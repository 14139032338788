<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <div>
    <!-- Search input -->
    <!-- <div class="d-flex align-items-center mb-3">
      <div class="h3 m-0" style="flex: 3;">Total: {{ totalRows }}</div>
    </div> -->
    <!-- Sort -->
    <div v-if="filter_status_button && filter_payment_button" class="d-flex align-items">
      <b-col md="3" class="my-1">
        <b-form-group
          v-if="sort_button"
          :label="$t('Filter')"
          label-cols-sm="12"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 d-flex align-items-center justify-content-center"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="$t('Type to Search')"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                {{ $t('Clear') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="3" class="my-1">
        <!-- Sort -->
        <b-form-group
          v-if="sort_button"
          :label="$t('Sorted by')"
          label-cols-sm="12"
          label-align-sm="left"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0 d-flex align-items-center justify-content-center"
          style="flex: 1;"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">
                  {{ $t('none') }}
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
            >
              <option :value="false">
                {{ $t('Asc') }}
              </option>
              <option :value="true">
                {{ $t('Desc') }}
              </option>
            </b-form-select>
          </b-input-group>statusOptions
        </b-form-group>
      </b-col>
      <b-col md="3" class="my-1">
        <!-- Sort -->
        <b-form-group
          v-if="filter_status_button"
          :label="$t('Status')"
          label-cols-sm="12"
          label-align-sm="left"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0 d-flex align-items-center justify-content-center"
          style="flex: 1;"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="filter"
              :options="statusOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">
                  {{ $t('none') }}
                </option>
              </template>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="3" class="my-1">
        <!-- Sort -->
        <b-form-group
          v-if="filter_payment_button"
          :label="$t('Payment status')"
          label-cols-sm="12"
          label-align-sm="left"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0 d-flex align-items-center justify-content-center"
          style="flex: 1;"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="filter"
              :options="statusPaymentOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">
                  {{ $t('none') }}
                </option>
              </template>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
    </div>
    <div v-else class="d-flex align-items-center mb-2">
      <div v-if="add_new_button" style="flex: 1;">
        <b-link to="add">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
          >
            <feather-icon icon="PlusCircleIcon" class="mr-50" />
            <span class="align-middle">{{ $t('Add new') }}</span>
          </b-button>
        </b-link>
      </div>
      <b-col v-if="add_product_category_button">
        <b-button
          v-b-modal.modal-xl
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="primary"
        >
          <feather-icon icon="PlusCircleIcon" class="mr-50" />
          <span class="align-middle">{{ $t('Assign products') }}</span>
        </b-button>
      </b-col>
      <!-- <b-col v-else md="4" /> -->

      <b-col md="4" class="my-1">
        <b-form-group
          v-if="sort_button"
          :label="$t('Filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 d-flex align-items-center justify-content-center"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="$t('Type to Search')"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                {{ $t('Clear') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col v-if="filter_date_select" md="4" class="my-1">
        <div>
          <b-form-group
            :label="$t('Time frame')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 d-flex align-items-center justify-content-center"
          >
            <b-input-group size="sm">
              <flat-pickr
                v-model="date_to"
                class="form-control"
                :config="{
                  enableTime: true,
                  minDate: 'today',
                  dateFormat: 'Y-m-d H:i:ss',
                }"
                :placeholder="$t('Choose the time you want to search')"
              />
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>

      <b-col v-if="sort_a_z && sort_button" md="4" class="my-1">
        <!-- Sort -->
        <b-form-group
          :label="$t('Sorted by')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0 d-flex align-items-center justify-content-center"
          style="flex: 1;"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">
                  {{ $t('none') }}
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
            >
              <option :value="false">
                {{ $t('Asc') }}
              </option>
              <option :value="true">
                {{ $t('Desc') }}
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
    </div>

    <!-- tan customer table -->
    <div v-if="sort_filter_customer" class="d-flex align-items-center mb-2">
      <div style="flex: 1;">
        <b-link to="add">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
          >
            <feather-icon icon="PlusCircleIcon" class="mr-50" />
            <span class="align-middle">{{ $t('Add new') }}</span>
          </b-button>
        </b-link>
      </div>
      <!-- <b-col v-else md="4" /> -->

      <b-col md="4" class="my-1">
        <b-form-group
          :label="$t('Filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 d-flex align-items-center justify-content-center"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="$t('Type to Search')"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                {{ $t('Clear') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col md="4" class="my-1">
        <div>
          <b-form-group
            :label="$t('Time frame')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 d-flex align-items-center justify-content-center"
          >
            <b-input-group size="sm">
              <flat-pickr
                v-model="date_to"
                class="form-control"
                :config="{
                  enableTime: true,
                  minDate: 'today',
                  dateFormat: 'Y-m-d H:i:ss',
                }"
                :placeholder="$t('Choose the time you want to search')"
              />
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      borderless
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #head()="data">
        <span>{{ $t(data.label) }}</span>
      </template>
      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
        </b-form-checkbox>
      </template>
      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Full Name : </strong>Nguyen van test
            </b-col>
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-row>
        </b-card>
      </template>
      <template #cell(discount_price)="item">
        <b-input-group append="| %" class="input-group-merge" style="width: 120px">
          <b-form-input v-model="value.list_product[item.index]['discount_price']" type="number" :value="item.item.discount_price" :min="0" :max="100" @change="handlerChangeDiscount(value.list_product[item.index]['discount_price'], item.index)" />
        </b-input-group>
      </template>
      <template #cell(checkbox)="item">
        <b-form-checkbox v-model="value.list_product" :value="item.item" />
      </template>
      <template #cell(checkbox_hide)="item">
        <b-form-checkbox v-model="value.list_product" :disabled="item.item.list_id_promotion == null ? false : true" :value="item.item" />
      </template>
      <template #cell(no)="item">
        {{ item.index + 1 }}
      </template>
      <template #cell(avatar)="item">
        <b-avatar :src="item.item.avatar" />
      </template>
      <template #cell(avatar_name)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            rounded
            size="48"
            variant="light-company"
            class="mr-1"
            :src="data.item.avatar"
          >
            <!-- <b-img
              :src="data.item.avatar"
              alt="avatar img"
            /> -->
          </b-avatar>
          <div>
            <div class="font-weight-bolder">
              {{ data.item.name }}
            </div>
            <div class="font-small-3">
              {{ (data.item.sku ? data.item.sku : 'mo ta') }}
            </div>
          </div>
        </div>
      </template>
      <template #cell(warehouse_name)="item">
        <b-link
          :to="
            `edit/${item.item.id}?lang=${currentLang}&product_id=${item.item.product_id}`
          "
        >
          {{ item.item.warehouse_name }}
        </b-link>
      </template>
      <template #cell(flashsale)="item">
        <b-link v-if="item.item.type === 0" :to="`edit/${item.item.id}?lang=${currentLang}`">
          {{ item.item.name }}
        </b-link>
        <b-link v-else :to="`/flash-sale/edit/${item.item.id}?lang=${currentLang}`">
          {{ item.item.name }}
        </b-link>
      </template>
      <template #cell(name)="item">
        <b-link v-if="customLink && customLink !== ''" :to="`/${customLink}/${item.item.id}?lang=${currentLang}`">
          {{ item.item.name }}
        </b-link>
        <b-link v-else :to="`edit/${item.item.id}?lang=${currentLang}`">
          {{ item.item.name }}
        </b-link>
      </template>
      <template #cell(name_promotion)="item">
        <b-link v-for="(val, index) in item.item.list_promotion" :key="index" class="d-block" :to="`/promotion/edit/${val.id}?lang=${currentLang}`">
          {{ index + 1 }}. {{ val.name }}
        </b-link>
      </template>
      <template #cell(name_product_attribute)="item">
        <div>
          <b-link v-if="customLink && customLink !== ''" :to="`/${customLink}/${item.item.id}?lang=${currentLang}`">
            <span>{{ item.item.name }}</span>
          </b-link>
          <b-link v-else :to="`edit/${item.item.id}?lang=${currentLang}`">
            <span>{{ item.item.name }}</span>
          </b-link>
          <small v-if="item.item.name_attribute" class="text-success d-block">Phân loại: {{ item.item.name_attribute }}</small>
        </div>
      </template>
      <template #cell(domain_name)="item">
        <b-link :href="`https://${item.item.domain_name}`" target="_blank">
          {{ item.item.domain_name }}
        </b-link>
      </template>
      <template #cell(order_no)="item">
        <b-link :to="`edit/${item.item.id}?lang=${currentLang}`">
          {{ item.item.order_no }}
        </b-link>
      </template>
      <!-- <template #cell(position)="item">
        <div class="d-flex align-item-center">
          <b-form-input
            :value="item.item.position"
            type="number"
            class="text-center btn-sm mr-1"
            style="max-width: 60px; width: 100%;"
          />
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            type="button"
            variant="outline-info"
            class="btn-sm"
            @click="handlerSort(item, 'change')"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </div>
      </template> -->
      <template #cell(title)="item">
        <b-link :to="`edit/${item.item.id}?lang=${currentLang}`">
          {{ item.item.title }}
        </b-link>
      </template>
      <template #cell(content)="item">
        <div class="content-w">
          {{ item.item.content }}
        </div>
      </template>
      <template #cell(email)="item">
        <b-link :to="`edit/${item.item.id}?lang=${currentLang}`">
          {{ item.item.email }}
        </b-link>
      </template>
      <template #cell(fullname)="item">
        <b-link :to="`edit/${item.item.id}?lang=${currentLang}`">
          {{ item.item.fullname }}
        </b-link>
      </template>
      <template #cell(rating)="item">
        <b-form-rating
          :value="item.item.rating"
          no-border
          variant="warning"
          readonly
          size="sm"
          inline
        />
      </template>
      <template #cell(created_at_stamp)="item">
        {{ getTimestampDate(false, '/', item.item.created_at_stamp) }}
      </template>
      <template #cell(created_at)="item">
        {{ item.item.created_at }}
      </template>
      <template #cell(created_at_utc)="item">
        {{ dateFormatUTC(item.item.created_at_utc) }}
      </template>
      <template #cell(updated_at_utc)="item">
        {{ dateFormatUTC(item.item.updated_at_utc) }}
      </template>
      <template #cell(date)="item">
        {{ dateFormatUTC(item.item.date_from) }} - {{ dateFormatUTC(item.item.date_to) }}
        <!-- {{ dateFormatUTC(item.item.created_at) }} -->
      </template>
      <template #cell(type)="item">
        {{ item.item.type === 1 ? 'Flash sale' : 'Promotion' }}
        <!-- {{ dateFormatUTC(item.item.created_at) }} -->
      </template>

      <template #cell(dateAndStatus)="item">
        <ActiveStatus :value="item.item.is_active" />
        <div>{{ dateFormatUTC(item.item.date_from) }} -</div>
        <div>{{ dateFormatUTC(item.item.date_to) }}</div>
      </template>

      <template #cell(after_discount_price)="item">
        {{ currencyFormat(item.item.after_discount_price) }}
      </template>
      <template #cell(sold_flash_sale)="item">
        <b-input-group append="| item" class="input-group-merge" style="width: 120px">
          <b-form-input v-model="value.list_product[item.index]['sold']" type="number" :value="item.item.sold" :min="0" :max="100" />
        </b-input-group>
      </template>
      <template #cell(total)="item">
        {{ currencyFormat(item.item.total) }}
      </template>
      <template #cell(subTotal)="item">
        <strong>{{ currencyFormat(item.item.subTotal) }}</strong>
      </template>
      <template #cell(is_active)="item">
        <ActiveStatus :value="item.item.is_active" />
      </template>
      <template #cell(is_status)="item">
        <b-badge :variant="activeIsStatus(item.item.status.id)">
          {{ item.item.status.name }}
        </b-badge>
      </template>
      <template #cell(role)="item">
        <div class="text-nowrap">
          <feather-icon
            :icon="roleIcon(item.item.role)"
            size="18"
            class="mr-50"
            :class="`text-${roleVariant(item.item.role)}`"
          />
          <span class="align-text-top text-capitalize">{{ item.item.role }}</span>
        </div>
      </template>
      <template #cell(is_warehouse)="item">
        <ActiveStatus :value="item.item.is_warehouse" />
      </template>
      <template #cell(status_no)="item">
        <OrderStatus :value="item.item.status_no" />
      </template>
      <template #cell(payment_type_text)="item">
        <PaymentStatus :value="item.item.payment_type_text" />
      </template>
      <template #cell(check_active)="item">
        <b-form-checkbox
          v-model="item.item.check_active"
          checked="true"
          class="custom-control-success"
          name="check-button"
          switch
        />
      </template>
      <template #cell(click_view)="item">
        {{ item.item.click_view }}
      </template>
      <template #cell(Shock_Deal_Name)="item">
        {{ item.item.Shock_Deal_Name }}
      </template>
      <template #cell(bundled_products)="item">
        {{ item.item.bundled_products }}
      </template>
      <template #cell(Shock_Deal_Type)="item">
        {{ item.item.Shock_Deal_Type }}
      </template>
      <template #cell(toolList)="item">
        <b-link
          :to="{ path: `/${item.item.id}` }"
          class="text-secondary d-block mt-1"
        >
          {{ $t('Edit') }}
        </b-link>
        <b-link
          :to="{ path: `/${item.item.id}` }"
          class="text-secondary d-block mt-1"
        >
          {{ $t('Close') }}
        </b-link>
      </template>
      <template #cell(lang)="item">
        <div class="text-center">
          <LanguageSelect :value="item" :languageList="languageList" />
        </div>
      </template>
      <template #cell(more)="item">
        <div class="text-center">
          <!-- <b-link
            :to="{ path: `detail/${item.item.id}` }"
            class="text-secondary"
          >
            <feather-icon icon="EyeIcon" class="mr-50" />
          </b-link> -->
          <!-- <b-link
            :to="{ path: `edit/${item.item.id}` }"
            class="text-secondary mx-1"
          >
            <feather-icon icon="Edit3Icon" class="mr-50" />
          </b-link> -->
          <div class="text-danger" @click="handlerDelete(item.item.id)">
            <feather-icon icon="TrashIcon" />
          </div>
        </div>
      </template>
      <template #cell(more_drop)="item">
        <b-dropdown
          variant="link"
          toggle-class="p-0"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item v-if="check_detail" @click="linkEdit(edit_link, item.item.id)">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Detail</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="check_edit" @click="linkEdit(edit_link, item.item.id)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="check_delete" @click.native="handlerDelete(item.item.id)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(more_btn)="item">
        <div class="">

          <b-link
            :to="{ path: `/${item.item.id}` }"
            class="text-secondary d-block mt-1"
          >
            Approve
          </b-link>
          <b-link
            :to="{ path: `/${item.item.id}` }"
            class="text-secondary d-block mt-1"
          >
            Reply
          </b-link>
          <b-link
            :to="{ path: `/${item.item.id}` }"
            class="text-secondary d-block mt-1"
          >
            Edit
          </b-link>
          <b-link
            v-b-modal.modal-detail-comment
            class="text-secondary d-block mt-1"
          >
            Detail
          </b-link>
          <b-link
            :to="{ path: `/${item.item.id}` }"
            class="text-danger d-block mt-1"
          >
            Remove
          </b-link>
        </div>
      </template>
      <template #cell(sync)="item">
        <b-link :to="`sync-${channel}/${item.item.id}?lang=${currentLang}`">
          <feather-icon icon="RefreshCwIcon" />
        </b-link>
      </template>
      <template #cell(sort)="item" class="text-center">
        <div class="text-center">
          <feather-icon
            :class="{ disabled: items[item.index - 1] === undefined }"
            icon="ArrowUpIcon"
            class="text-success mr-1"
            size="18"
            @click="handlerSort(item, 'up')"
          />
          <feather-icon
            :class="{ disabled: items[item.index + 1] === undefined }"
            icon="ArrowDownIcon"
            class="text-danger"
            size="18"
            @click="handlerSort(item, 'down')"
          />
        </div>
      </template>
      <template #cell(sku)="item">
        <div v-for="val in item.item.warehouse" :key="val.id_product" class="bd-b-1">
          {{ val.sku }}
        </div>
      </template>
      <template #cell(price)="item">
        <!-- <div v-for="val in item.item.warehouse" :key="val.id_product" class="bd-b-1">
          {{ currencyFormat(item.item.price + val.price) }}
        </div> -->
        <div class="bd-b-1">
          {{ avgPrice(item) }}
        </div>
      </template>
      <template #cell(stock)="item" class="text-center">
        <!-- <div v-for="val in item.item.warehouse" :key="val.id_product" class="bd-b-1">
          {{ val.quantity }}
        </div> -->
        {{ sumStock(item) }}
      </template>
      <template #cell(sold)="item" class="text-center">
        <div v-for="val in item.item.warehouse" :key="val.id_product" class="bd-b-1">
          {{ val.sold }}
        </div>
      </template>
    </b-table>
    <b-row v-if="paging">
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">{{ $t('Per page') }}</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-75"
            @change="handlerChangePerPage(perPage)"
          />
        </b-form-group>
      </b-col>
      <b-col md="10" sm="8" class="my-1">
        <b-pagination
          v-if="currentPage"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
          @change="handlerChangePage"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
/* eslint-disable vue/prop-name-casing */
/* eslint-disable operator-linebreak */
import {
  BAvatar,
  BPagination,
  BTable,
  BRow,
  BCol,
  BButton,
  BLink,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormRating,
  BFormCheckbox,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BBadge,
  // BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import LanguageSelect from '@/views/components/language-select/index.vue'
import OrderStatus from '@/views/components/order-status/index.vue'
import PaymentStatus from '@/views/components/order-status/payment_status.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BButton,
    BLink,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    LanguageSelect,
    BAvatar,
    BPagination,
    BTable,
    BRow,
    BCol,
    BFormRating,
    BFormCheckbox,
    BFormInput,
    BInputGroupAppend,
    OrderStatus,
    PaymentStatus,
    flatPickr,
    BDropdown,
    BDropdownItem,
    BBadge,
    // BImg,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    add_new_button: {
      type: Boolean,
      default: true,
    },
    filter_date_select: {
      type: Boolean,
      default: false,
    },
    add_product_category_button: {
      type: Boolean,
      default: false,
    },
    sort_button: {
      type: Boolean,
      default: true,
    },
    filter_payment_button: {
      type: Boolean,
      default: false,
    },
    filter_status_button: {
      type: Boolean,
      default: false,
    },
    per_page: {
      type: Number,
      default: 10,
    },
    paging: {
      type: Boolean,
      default: true,
    },
    customLink: {
      type: String,
      default: '',
    },
    channel: {
      type: String,
      default: '',
    },
    sort_filter_customer: {
      type: Boolean,
      default: false,
    },
    check_delete: {
      type: Boolean,
      default: false,
    },
    check_edit: {
      type: Boolean,
      default: false,
    },
    check_detail: {
      type: Boolean,
      default: false,
    },
    sort_a_z: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      languageList: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      date_to: null,
      pageOptions: [
        5,
        10,
        20,
        this.per_page !== 5 && this.per_page !== 10 && this.per_page !== 20
          ? this.per_page
          : 30,
      ],
      perPage: this.per_page,
      totalRows: 100,
      currentPage: 1,
      currentLang: JSON.parse(localStorage.getItem('siteID')).language,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    statusOptions() {
      return [
        { text: 'Waiting for confirmation', value: 'Waiting for confirmation' },
        { text: 'To process', value: 'To process' },
        { text: 'Processed', value: 'Processed' },
        { text: 'Shipping', value: 'Shipping' },
        { text: 'Completed', value: 'Completed' },
        { text: 'Cancel', value: 'Cancel' },
        { text: 'Return', value: 'Return' },
        { text: 'Refund', value: 'Refund' },
        { text: 'Refund processed', value: 'Refund processed' },
      ]
    },
    statusPaymentOptions() {
      return [
        { text: 'COD', value: 'COD' },
        { text: 'Transfer money', value: 'Transfer money' },
        // { text: 'Paid', value: 'Paid' },
        // { text: 'Cancel', value: 'Cancel' },
      ]
    },
  },
  watch: {
    per_page(val) {
      if (val) {
        this.perPage = val
      }
    },
    items(val) {
      if (val) {
        this.loadData()
      }
    },
    // eslint-disable-next-line object-shorthand
    '$route.query.page'(val) {
      if (val) {
        this.loadData()
      }
    },
    // eslint-disable-next-line object-shorthand
    '$route.query.per_page'(val) {
      if (val) {
        this.loadData()
      }
    },
  },
  async mounted() {
    this.languageList = await this.loadLanguage()
    // this.loadData()
  },
  created() {
    // this.loadData()
  },
  methods: {
    loadData() {
      this.currentPage =
      this.$route.query.page && this.$route.query.page !== undefined
        ? this.$route.query.page
        : 1
      // console.log(this.currentPage)
      this.perPage =
      this.$route.query.per_page && this.$route.query.per_page !== undefined
        ? this.$route.query.per_page
        : this.per_page
      this.totalRows = this.items.length
    },
    handlerChangePage(page) {
      this.$router.replace({ query: { page, per_page: this.perPage } })
    },
    handlerChangePerPage(perPage) {
      this.$router.replace({ query: { per_page: perPage } })
    },
    handlerSort(item, method) {
      this.$emit('handlerSort', { item, method })
    },
    handlerDelete(id) {
      this.$emit('handlerDelete', id)
    },
    handlerProductCategory() {
      this.$emit('handlerProductCategory')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handlerChangeDiscount(percent, index) {
      this.value.list_product[index].discount_price = this.handlePercent(percent)
      this.value.list_product[index].after_discount_price = this.value.list_product[index].price - ((this.handlePercent(percent) * this.value.list_product[index].price) / 100)
    },
    activeIsStatus(status) {
      if (status === 0) return 'light-warning'
      if (status === 1) return 'light-success'
      if (status === 2) return 'light-secondary'
      return 'primary'
    },
    roleIcon(icon) {
      if (icon === 'subscriber') return 'UserIcon'
      if (icon === 'author') return 'SettingsIcon'
      if (icon === 'maintainer') return 'DatabaseIcon'
      if (icon === 'editor') return 'Edit2Icon'
      if (icon === 'admin') return 'ServerIcon'
      return 'UserIcon'
    },
    roleVariant(color) {
      if (color === 'subscriber') return 'primary'
      if (color === 'author') return 'warning'
      if (color === 'maintainer') return 'success'
      if (color === 'editor') return 'info'
      if (color === 'admin') return 'danger'
      return 'primary'
    },
    avgPrice(data) {
      if (data.item.warehouse) {
        const prices = []
        data.item.warehouse.map(val => {
          prices.push(val.price)
          return true
        })
        let min = Math.min(...prices)
        if (Number.isNaN(min)) {
          min = 0
        }

        let max = Math.max(...prices)
        if (Number.isNaN(max)) {
          max = 0
        }

        if (min === max) {
          return `${this.currencyFormat(min)}`
        }
        return `${this.currencyFormat(min)} - ${this.currencyFormat(max)}`
      }
      return false
    },
    sumStock(data) {
      if (data.item.warehouse) {
        let sum = 0
        data.item.warehouse.map(val => {
          sum += Number(val.quantity)
          return true
        })
        return sum
      }
      return false
    },
  },
}
</script>
<style lang="sass">
.content-w
  max-width: 350px
.bd-b-1
  margin-bottom: 8px
</style>
