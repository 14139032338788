<template>
  <div>
    <div class="custom-search">
      <!-- advance search input -->
      <b-row>
        <b-col md="4">
          <b-form-group :label="$t('Sorted by')">
            <b-form-select
              id="sortBySelect"
              :options="statusOptions"
              class="w-75"
              @change="advanceSearch"
            >
              <template v-slot:first>
                <option value="">
                  {{ $t('none') }}
                </option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="$t('Sorted by')">
            <b-form-select
              id="sortBySelect"
              :options="statusPaymentOptions"
              class="w-75"
              @change="advanceSearch"
            >
              <template v-slot:first>
                <option value="">
                  {{ $t('none') }}
                </option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="items"
      :rtl="direction"
      :group-options="{
        enabled: true,
        headerPosition: 'top'
      }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
      style-class="vgt-table striped"
    >
      <template
        slot="table-header-row"
        slot-scope="props"
      >
        <span class="my-fancy-class">
          {{ props.row }}
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'created_at_utc'"
          class="text-nowrap"
        >
          {{ dateFormatUTC(props.formattedRow.created_at_utc) }}
        </span>
        <span
          v-else-if="props.column.field === 'status_no'"
          class="text-nowrap"
        >
          <OrderStatus :value="props.formattedRow.status_no" />
        </span>
        <span
          v-else-if="props.column.field === 'total'"
          class="text-nowrap"
        >
          {{ currencyFormat(props.formattedRow.total) }}
        </span>
        <span
          v-else-if="props.column.field === 'fullname'"
          class="text-nowrap"
        >
          <b-link
            v-if="customLink && customLink !== ''"
            :to="`/${customLink}/${props.formattedRow.order_no}?lang=${currentLang}`"
          >
            {{ props.formattedRow.fullname }}
          </b-link>
          <b-link
            v-else
            :to="`edit/${props.formattedRow.order_no}?lang=${currentLang}`"
          >
            {{ props.formattedRow.fullname }}
          </b-link>
        </span>
        <span
          v-else-if="props.column.field === 'order_no'"
          class="text-nowrap"
        >
          <b-link
            v-if="customLink && customLink !== ''"
            :to="`/${customLink}/${props.formattedRow.order_no}?lang=${currentLang}`"
          >
            {{ props.formattedRow.order_no }}
          </b-link>
          <b-link
            v-else
            :to="`edit/${props.formattedRow.order_no}?lang=${currentLang}`"
          >
            {{ props.formattedRow.order_no }}
          </b-link>
        </span>
        <span
          v-else-if="props.column.field === 'payment_type_text'"
          class="text-nowrap"
        >
          <PaymentStatus :value="props.formattedRow.payment_type_text" />
        </span>
        <span
          v-else-if="props.column.field === 'more'"
          class="text-nowrap"
        >
          <div
            class="text-danger"
            @click="handlerDelete(props.formattedRow.id)"
          >
            <feather-icon icon="TrashIcon" />
          </div>
        </span>
        <span
          v-else-if="props.column.field === 'position'"
          class="text-nowrap"
        >
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            type="button"
            variant="outline-info"
            class="btn-sm"
            @click="handlerSort(props.formattedRow, 'change')"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','20']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  // BAvatar,
  BLink,
  BPagination,
  BFormGroup,
  // BFormInput,
  BFormSelect,
  BRow,
  BCol,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import general from '@/mixins/index'
import store from '@/store/index'
import OrderStatus from '@/views/components/order-status/index.vue'
import PaymentStatus from '@/views/components/order-status/payment_status.vue'

export default {
  components: {
    VueGoodTable,
    // BAvatar,
    BLink,
    BPagination,
    BFormGroup,
    // BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    OrderStatus,
    PaymentStatus,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    paging: {
      type: Boolean,
      default: true,
    },
    customLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      searchTerm: '',
      currentLang: JSON.parse(localStorage.getItem('siteID')).language,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusOptions() {
      return [
        { text: 'Waiting for confirmation', value: 'Waiting for confirmation' },
        { text: 'To process', value: 'To process' },
        { text: 'Processed', value: 'Processed' },
        { text: 'Shipping', value: 'Shipping' },
        { text: 'Completed', value: 'Completed' },
        { text: 'Cancel', value: 'Cancel' },
        { text: 'Return', value: 'Return' },
        { text: 'Refund', value: 'Refund' },
        { text: 'Refund processed', value: 'Refund processed' },
      ]
    },
    statusPaymentOptions() {
      return [
        { text: 'COD', value: 'COD' },
        { text: 'Transfer money', value: 'Transfer money' },
        // { text: 'Paid', value: 'Paid' },
        // { text: 'Cancel', value: 'Cancel' },
      ]
    },
  },
  methods: {
    advanceSearch(val) {
      this.searchTerm = val
    },
    handlerSort(item, method) {
      this.$emit('handlerSort', { item, method })
    },
    handlerDelete(id) {
      this.$emit('handlerDelete', id)
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
