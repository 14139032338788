<template>
  <!-- eslint-disable vue/html-self-closing -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- <b-dropdown variant="link" no-caret toggle-class="p-0" right>
    <template #button-content>
      <feather-icon icon="GlobeIcon" size="18" class="align-middle text-body" />
    </template>

    <b-dropdown-item
      v-for="(item, index) in languageList"
      :key="index"
      :to="{ path: `edit/${value.item.id}?lang=${item.locale}` }"
    >
      <div class="d-flex align-items-center">
        <b-aspect aspect="16-9">
          <img
            :src="item.img"
            :alt="item.locale"
            class="mr-1"
            style="width: 25px;"
          />
        </b-aspect>
        <span>{{ item.name }}</span>
      </div>
    </b-dropdown-item>
  </b-dropdown> -->
  <div class="d-flex align-items-center justify-content-center">
    <b-link
      v-for="(item, index) in languageList"
      :key="index"
      :to="{ path: `edit/${value.item.id}?lang=${item.locale}` }"
    >
      <b-aspect aspect="16-9">
        <img
          :src="item.img"
          :alt="item.locale"
          class="mr-1"
          style="width: 20px; height: 70%;"
        />
      </b-aspect>
    </b-link>
  </div>
</template>
<script>
import {
  BAspect,
  BLink,
  // BDropdown,
  // BDropdownItem
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'

export default {
  components: {
    BAspect,
    BLink,
    // BDropdown,
    // BDropdownItem,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    languageList: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>
