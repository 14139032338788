export default [
  {
    path: '/affiliate/general',
    name: 'affiliate-general',
    component: () => import('@/views/affiliate-micro-services/general/General.vue'),
  },
  {
    path: '/affiliate/commission/category',
    name: 'affiliate-commission-category',
    component: () => import('@/views/affiliate-micro-services/commission/Category.vue'),
  },
  {
    path: '/affiliate/rank-segment',
    name: 'affiliate-rank-segment',
    component: () => import('@/views/affiliate-micro-services/rank-segment/List.vue'),
    meta: {
      navActiveLink: 'affiliate-rank-segment',
    },
  },
  {
    path: '/affiliate/rank-segment/edit',
    name: 'affiliate-rank-segment-edit',
    component: () => import('@/views/affiliate-micro-services/rank-segment/Edit.vue'),
    meta: {
      navActiveLink: 'affiliate-rank-segment',
    },
  },
  {
    path: '/affiliate/member/list',
    name: 'affiliate-member-list',
    component: () => import('@/views/affiliate-micro-services/member/List.vue'),
    meta: {
      navActiveLink: 'affiliate-member-list',
    },
  },
  {
    path: '/affiliate/member/edit/:id',
    name: 'affiliate-member-detail',
    component: () => import('@/views/affiliate-micro-services/member/Edit.vue'),
    meta: {
      navActiveLink: 'affiliate-member-list',
    },
  },
  {
    path: '/affiliate/member/points-history',
    name: 'affiliate-member-points-history',
    component: () => import('@/views/affiliate-micro-services/member/PointsHistory.vue'),
    meta: {
      navActiveLink: 'affiliate-member-list',
    },
  },
  {
    path: '/affiliate/referrals/add',
    name: 'affiliate-referrals-add',
    component: () => import('@/views/affiliate-micro-services/referrals/add.vue'),
  },
  {
    path: '/affiliate/payment/list',
    name: 'affiliate-payment-list',
    component: () => import('@/views/affiliate-micro-services/payment/Index.vue'),
    meta: {
      navActiveLink: 'affiliate-payment-list',
    },
  },
  {
    path: '/affiliate/payment/member/:id',
    name: 'affiliate-payment-member-edit',
    component: () => import('@/views/affiliate-micro-services/payment/Edit.vue'),
    meta: {
      navActiveLink: 'affiliate-payment-list',
    },
  },
  {
    path: '/affiliate/order/list',
    name: 'affiliate-order-list',
    component: () => import('@/views/affiliate-micro-services/orders/List.vue'),
  },
  {
    path: '/affiliate/report',
    name: 'affiliate-report',
    component: () => import('@/views/affiliate-micro-services/report/General.vue'),
  },
]
