import Vue from 'vue'
// Feather Icon
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
// Table
import CustomTable from '@/views/components/custom-table/index.vue'
// Good Table
import CustomGoodTable from '@/views/components/custom-table/index_good.vue'
// No data
import NoData from '@/views/components/nodata/index.vue'
// Fieldset (collapse)
import Fieldset from '@/views/components/fieldset/index.vue'
// Feature Image
import FeatureImage from '@/views/components/feature-image/index.vue'
// Preloader
import Preloader from '@core/layouts/components/Loading.vue'
// SEO Info
import SEOInfo from '@/views/components/seo/index.vue'
// Active Status
import ActiveStatus from '@/views/components/active-status/index.vue'
// Pagination custom
import Pagination from '@/views/components/pagination-custom/Pagination.vue'

// Feather Icon
Vue.component(FeatherIcon.name, FeatherIcon)
// Table
Vue.component('CustomTable', CustomTable)
// Good Table
Vue.component('CustomGoodTable', CustomGoodTable)
// No data
Vue.component('no-data', NoData)
// Fieldset (collapse)
Vue.component('Fieldset', Fieldset)
// Feature Image
Vue.component('FeatureImage', FeatureImage)
// Preloader
Vue.component('Overplay', Preloader)
// SEO Info
Vue.component('SEOInfo', SEOInfo)
// ActiveStatus
Vue.component('ActiveStatus', ActiveStatus)
// Pagination-Custom
Vue.component('Pagination', Pagination)
