<template>
  <!-- <b-badge
    :variant="`light-${active.color}`"
  >
    {{ active.key }}
  </b-badge> -->
  <div
    class="d-flex align-items-center h-100"
    :class="{
      'text-danger': value === 'Cancel',
      'text-info': value === 'COD',
      'text-warning': value === 'Transfer money',
      'text-success': value === 'Paid'
    }"
  >
    {{ value }}
  </div>
</template>

<script>
import statusList from '@/mixins/status.json'
// import { BBadge } from 'bootstrap-vue'

export default {
  // components: {
  //   BBadge,
  // },
  props: {
    value: {
      type: [Number, String, Boolean],
      default: 0,
    },
  },
  data() {
    return {
      statusList,
      active: {
        id: 1,
        key: 'Waiting for confirmation',
        note: 'Đợi xác nhận',
        color: 'success',
      },
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.active = this.statusList.filter(x => x.id === Number(val))
        // eslint-disable-next-line prefer-destructuring
        this.active = this.active[0]
      }
    },
  },
  mounted() {
    this.active = this.statusList.filter(x => x.id === Number(this.value))
    // eslint-disable-next-line prefer-destructuring
    this.active = this.active[0]
  },
}
</script>
