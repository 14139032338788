<template>
  <!-- eslint-disable vue/html-self-closing -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div
    class="d-flex align-items-center justify-content-center position-relative"
    :style="`margin: auto; max-width: ${maxWidth}px`"
  >
    <b-aspect aspect="1:1" class="position-relative">
      <b-img-lazy :src="newAvatar" fluid thumbnail rounded />
      <div
        class="fade-browse d-flex align-items-center justify-content-center w-100 h-100 rounded"
      >
        <b-form-file
          accept=".jpg, .png, .gif, .jpeg"
          class="w-100 h-100 d-flex align-items-center justify-content-center img-box"
          @change="previewFiles"
        />
        <b-button
          variant="outline-primary"
          class="btn-icon rounded-circle btn-add"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </div>
    </b-aspect>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
import { BButton, BFormFile, BImgLazy, BAspect } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'

export default {
  components: {
    BButton,
    BFormFile,
    BImgLazy,
    BAspect,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    avatar: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      newAvatar: '',
    }
  },
  watch: {
    avatar(val) {
      if (val) {
        this.newAvatar = val
      }
    },
  },
  mounted() {
    this.newAvatar =
      this.avatar !== undefined && this.avatar !== null
        ? this.avatar
        : this.avatarDefault()
  },
  methods: {
    previewFiles(input) {
      this.$emit('previewFiles', input)
    },
  },
}
</script>
<style lang="scss" scoped>
img {
  position: relative;
  width: 200px;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: absolute;
  z-index: 3;
  top: 0;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
