export default {
  state: {
    ghnStatus: {
      cancel: 'Cancel',
      damage: 'Damage',
      delivered: 'Delivered',
      delivering: 'Delivering',
      delivery_fail: 'Delivery fail',
      draft: 'Draft',
      draft_cancel: 'Draft cancel',
      exception: 'Exception',
      lost: 'Lost',
      money_collect_delivering: 'Money collect delivering',
      money_collect_picking: 'Money collect picking',
      picked: 'Picked',
      picking: 'Picking',
      ready_to_pick: 'Ready to pick',
      return: 'Return',
      return_fail: 'Return fail',
      return_sorting: 'Return sorting',
      return_transporting: 'Return transporting',
      returned: 'Returned',
      returning: 'Returning',
      sorting: 'Sorting',
      storing: 'Storing',
      transporting: 'Transporting',
      waiting_to_return: 'Waiting to return',
    },
  },
  mutations: {
    update(state, data) {
      state[data.key] = data.value
    },
  },
  actions: {},
}

/* Example Use:
let res = null
if (this.$store.state.gData.yourData) {
  res = this.$store.state.gData.yourData
  res = await Request.get(this.$http, 'apiUrl', {})
  this.$store.commit('gData/update', {
    key: 'yourData',
    value: res
  })
} */
