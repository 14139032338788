<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-row>
    <b-col md="7">
      <b-form-group :label="$t('Title page') + `:`" label-cols-md="12">
        <b-form-input v-model="value.title_page" :placeholder="$t('Title page')" />
      </b-form-group>
      <b-form-group label="Slug:" label-cols-md="12">
        <b-form-input
          v-model="value.slug"
          placeholder="Slug"
          @input="generateSlug(value.slug)"
        />
      </b-form-group>
      <b-form-group label="Meta keyword:" label-cols-md="12">
        <b-form-tags
          v-model="value.meta_keyword"
          input-id="tags-remove-on-delete"
          :input-attrs="{
            'aria-describedby': 'tags-remove-on-delete-help',
          }"
          :placeholder="$t('Enter new keyword separated by enter')"
          remove-on-delete
          class="mb-2"
        />
      </b-form-group>
      <b-form-group label="Meta description:" label-cols-md="12">
        <b-form-textarea
          v-model="value.meta_description"
          rows="6"
          max-rows="6"
          no-resize
          placeholder="Meta description"
        />
      </b-form-group>
    </b-col>
    <b-col md="5">
      <div class="seo-wrapper">
        <div class="meta-title">
          {{ value.title_page }}
        </div>
        <b-link
          v-if="value.slug !== null"
          :href="`https://${domainName}/${value.slug}`"
          class="meta-slug"
          target="_blank"
        >
          https://{{ domainName }}/{{ value.slug }}
        </b-link>
        <div class="meta-description">
          {{ value.meta_description }}
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormTextarea,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormTags,
  BLink,
} from 'bootstrap-vue'
import general from '@/mixins/index'

export default {
  components: {
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormTags,
    BLink,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      domainName: null,
    }
  },
  mounted() {
    this.domainName = JSON.parse(localStorage.getItem('siteID')).domain_name
  },
  methods: {
    generateSlug(name) {
      this.value.slug = this.convertToSlug(name)
    },
  },
}
</script>

<style lang="scss" scoped>
.seo-wrapper {
  min-height: 140px;
  background: #e8eaee;
  border-radius: 4px;
  padding: 16px 18px;
  .meta-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
  }
  .meta-slug {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #0b7428;
    margin: 6px auto 10px auto;
    display: block;
  }
  .meta-description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #707e8e;
  }
}
</style>
