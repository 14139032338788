export default [
  {
    path: '/',
    name: 'dashboard-homepage-v1',
    component: () => import('@/views/dashboard/homepage_v1/Homepage_v1.vue'),
  },
  // {
  //   path: '/dashboard-old',
  //   name: 'dashboard-homepage',
  //   component: () => import('@/views/dashboard/homepage/Homepage.vue'),
  // },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/site',
    name: 'site',
    component: () => import('@/views/pages/authentication/Site.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/me',
    name: 'auth-me',
    component: () => import('@/views/pages/authentication/Me.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  /** ****************************\
    * Gallery / Gallery category
  \***************************** */
  {
    path: '/gallery_category/list',
    name: 'gallery-category-list',
    component: () => import('@/views/pages/gallery/GalleryCategoryList.vue'),
    meta: {
      navActiveLink: 'gallery-category-list',
    },
  },
  {
    path: '/gallery_category/add',
    name: 'gallery-category-add',
    component: () => import('@/views/pages/gallery/GalleryCategoryAdd.vue'),
    meta: {
      navActiveLink: 'gallery-category-list',
    },
  },
  {
    path: '/gallery_category/edit/:id',
    name: 'gallery-category-edit',
    component: () => import('@/views/pages/gallery/GalleryCategoryEdit.vue'),
    meta: {
      navActiveLink: 'gallery-category-list',
    },
  },
  {
    path: '/gallery_category/detail/:id',
    name: 'gallery-category-detail',
    component: () => import('@/views/pages/gallery/GalleryCategoryDetail.vue'),
    meta: {
      navActiveLink: 'gallery-category-list',
    },
  },
  {
    path: '/gallery/list',
    name: 'gallery-list',
    component: () => import('@/views/pages/gallery/GalleryList.vue'),
  },
  {
    path: '/gallery/add',
    name: 'gallery-add',
    component: () => import('@/views/pages/gallery/GalleryAdd.vue'),
    meta: {
      navActiveLink: 'gallery-list',
    },
  },
  {
    path: '/gallery/edit/:id',
    name: 'gallery-edit',
    component: () => import('@/views/pages/gallery/GalleryEdit.vue'),
    meta: {
      navActiveLink: 'gallery-list',
    },
  },
  {
    path: '/gallery/detail/:id',
    name: 'gallery-detail',
    component: () => import('@/views/pages/gallery/GalleryDetail.vue'),
    meta: {
      navActiveLink: 'gallery-list',
    },
  },
  /** ****************************\
    * Blog / Blog category
  \***************************** */
  {
    path: '/blog_category/list',
    name: 'blog-category-list',
    component: () => import('@/views/pages/blog/BlogCategoryList.vue'),
  },
  {
    path: '/blog_category/add',
    name: 'blog-category-add',
    component: () => import('@/views/pages/blog/BlogCategoryAdd.vue'),
    meta: {
      navActiveLink: 'blog-category-list',
    },
  },
  {
    path: '/blog_category/edit/:id',
    name: 'blog-category-edit',
    component: () => import('@/views/pages/blog/BlogCategoryEdit.vue'),
    meta: {
      navActiveLink: 'blog-category-list',
    },
  },
  {
    path: '/blog_category/detail/:id',
    name: 'blog-category-detail',
    component: () => import('@/views/pages/blog/BlogCategoryDetail.vue'),
    meta: {
      navActiveLink: 'blog-category-list',
    },
  },
  {
    path: '/blog/list',
    name: 'blog-list',
    component: () => import('@/views/pages/blog/BlogList.vue'),
  },
  {
    path: '/blog/add',
    name: 'blog-add',
    component: () => import('@/views/pages/blog/BlogAdd.vue'),
    meta: {
      navActiveLink: 'blog-list',
    },
  },
  {
    path: '/blog/edit/:id',
    name: 'blog-edit',
    component: () => import('@/views/pages/blog/BlogEdit.vue'),
    meta: {
      navActiveLink: 'blog-list',
    },
  },
  {
    path: '/blog/detail/:id',
    name: 'blog-detail',
    component: () => import('@/views/pages/blog/BlogDetail.vue'),
    meta: {
      navActiveLink: 'blog-list',
    },
  },
  /** ****************************\
    * Recruitment / Recruitment category
  \***************************** */
  {
    path: '/recruitment_category/list',
    name: 'recruitment-category-list',
    component: () => import('@/views/pages/recruitment/RecruitmentCategoryList.vue'),
  },
  {
    path: '/recruitment_category/add',
    name: 'recruitment-category-add',
    component: () => import('@/views/pages/recruitment/RecruitmentCategoryAdd.vue'),
    meta: {
      navActiveLink: 'recruitment-category-list',
    },
  },
  {
    path: '/recruitment_category/edit/:id',
    name: 'recruitment-category-edit',
    component: () => import('@/views/pages/recruitment/RecruitmentCategoryEdit.vue'),
    meta: {
      navActiveLink: 'recruitment-category-list',
    },
  },
  {
    path: '/recruitment_category/detail/:id',
    name: 'recruitment-category-detail',
    component: () => import('@/views/pages/recruitment/RecruitmentCategoryDetail.vue'),
    meta: {
      navActiveLink: 'recruitment-category-list',
    },
  },
  {
    path: '/recruitment/list',
    name: 'recruitment-list',
    component: () => import('@/views/pages/recruitment/RecruitmentList.vue'),
  },
  {
    path: '/recruitment/add',
    name: 'recruitment-add',
    component: () => import('@/views/pages/recruitment/RecruitmentAdd.vue'),
    meta: {
      navActiveLink: 'recruitment-list',
    },
  },
  {
    path: '/recruitment/edit/:id',
    name: 'recruitment-edit',
    component: () => import('@/views/pages/recruitment/RecruitmentEdit.vue'),
    meta: {
      navActiveLink: 'recruitment-list',
    },
  },
  {
    path: '/recruitment/detail/:id',
    name: 'recruitment-detail',
    component: () => import('@/views/pages/recruitment/RecruitmentDetail.vue'),
    meta: {
      navActiveLink: 'recruitment-list',
    },
  },
  /** ****************************\
    * Product
  \***************************** */
  {
    path: '/product_category/list',
    name: 'product-category-list',
    component: () => import('@/views/pages/product/ProductCategoryList.vue'),
    meta: {
      navActiveLink: 'product-category-add',
    },
  },
  {
    path: '/product_category/add',
    name: 'product-category-add',
    component: () => import('@/views/pages/product/ProductCategoryAddContainer.vue'),
    meta: {
      navActiveLink: 'product-category-add',
    },
  },
  // {
  //   path: '/product_category/add/:id',
  //   name: 'product-category-add',
  //   component: () => import('@/views/pages/product/ProductCategoryAddContainer.vue'),
  // },
  {
    path: '/product_category/edit/:id',
    name: 'product-category-edit',
    component: () => import('@/views/pages/product/ProductCategoryEditContainer.vue'),
    meta: {
      navActiveLink: 'product-category-add',
    },
  },
  {
    path: '/product_category/detail/:id',
    name: 'product-category-detail',
    component: () => import('@/views/pages/product/ProductCategoryDetail.vue'),
    meta: {
      navActiveLink: 'product-category-add',
    },
  },
  {
    path: '/product/list',
    name: 'product-list',
    component: () => import('@/views/pages/product/ProductList.vue'),
  },
  {
    path: '/product/add/',
    name: 'product-add',
    component: () => import('@/views/pages/product/ProductAdd.vue'),
    meta: {
      navActiveLink: 'product-list',
    },
  },
  {
    path: '/product/edit/:id',
    name: 'product-edit',
    component: () => import('@/views/pages/product/ProductEditV2.vue'),
    meta: {
      navActiveLink: 'product-list',
    },
  },
  {
    path: '/product/edit-v1/:id',
    name: 'product-edit-v1',
    component: () => import('@/views/pages/product/ProductEdit.vue'),
    meta: {
      navActiveLink: 'product-list',
    },
  },
  {
    path: '/product-v2/add/',
    name: 'product-add-v2',
    component: () => import('@/views/pages/product_v2/ProductAdd.vue'),
    meta: {
      navActiveLink: 'product-list',
    },
  },
  {
    path: '/product-v2/edit/:id',
    name: 'product-edit-v2',
    component: () => import('@/views/pages/product_v2/ProductEdit.vue'),
    meta: {
      navActiveLink: 'product-list',
    },
  },
  {
    path: '/product/detail/:id',
    name: 'product-detail',
    component: () => import('@/views/pages/product/ProductDetail.vue'),
    meta: {
      navActiveLink: 'product-list',
    },
  },
  /** ****************************\
    * Order
  \***************************** */
  {
    path: '/order/list',
    name: 'order-list',
    component: () => import('@/views/pages/order/OrderListV2.vue'),
  },
  {
    path: '/order-v2/list',
    name: 'order-v2-list',
    component: () => import('@/views/pages/order/OrderListV2.vue'),
  },
  {
    path: '/order/add',
    name: 'order-add',
    component: () => import('@/views/pages/order/OrderAdd.vue'),
  },
  {
    path: '/order/shopee',
    name: 'order-list-shopee',
    component: () => import('@/views/pages/order/sale-channel/OrderShopee.vue'),
    meta: {
      pageTitle: 'Order Shopee',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/order/shopee/edit/:id',
    name: 'order-list-shopee-edit',
    component: () => import('@/views/pages/order/sale-channel/OrderDetail.vue'),
    meta: {
      pageTitle: 'Order Shopee',
      breadcrumb: [
        {
          text: 'List',
          to: '/order/shopee',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/order/cancel',
    name: 'order-cancel',
    component: () => import('@/views/pages/order/OrderCancel.vue'),
  },
  {
    path: '/order/return',
    name: 'order-return',
    component: () => import('@/views/pages/order/OrderReturn.vue'),
  },
  {
    path: '/order/edit/:id',
    name: 'order-edit',
    component: () => import('@/views/pages/order/OrderEdit.vue'),
    meta: {
      navActiveLink: 'order-list',
    },
  },
  /** ****************************\
    * Warehouse List - danh sach kho/chi nhanh
  \***************************** */
  {
    path: '/warehouse-list/list',
    name: 'warehouselist-list',
    component: () => import('@/views/pages/warehouse/warehouseList/WareHouseList.vue'),
  },
  {
    path: '/warehouse-list/add',
    name: 'warehouselist-add',
    component: () => import('@/views/pages/warehouse/warehouseList/WareHouseAdd.vue'),
    meta: {
      navActiveLink: 'warehouselist-list',
    },
  },
  {
    path: '/warehouse-list/edit/:id',
    name: 'warehouselist-edit',
    component: () => import('@/views/pages/warehouse/warehouseList/WareHouseEdit.vue'),
    meta: {
      navActiveLink: 'warehouselist-list',
    },
  },
  /** ****************************\
    * Shelf List - danh sach ke hang trong kho
  \***************************** */
  {
    path: '/shelf/list',
    name: 'shelf-list',
    component: () => import('@/views/pages/warehouse/shelf/ShelfList.vue'),
  },
  {
    path: '/shelf/add',
    name: 'shelf-add',
    component: () => import('@/views/pages/warehouse/shelf/ShelfAdd.vue'),
    meta: {
      navActiveLink: 'shelf-list',
    },
  },
  {
    path: '/shelf/edit/:id',
    name: 'shelf-edit',
    component: () => import('@/views/pages/warehouse/shelf/ShelfEdit.vue'),
    meta: {
      navActiveLink: 'shelf-list',
    },
  },
  /** ****************************\
    * Warehouse
  \***************************** */
  {
    path: '/warehouse/list',
    name: 'warehouse-list',
    component: () => import('@/views/pages/warehouse/WareHouseList.vue'),
  },
  {
    path: '/warehouse/add',
    name: 'warehouse-add',
    component: () => import('@/views/pages/warehouse/WareHouseAdd.vue'),
    meta: {
      navActiveLink: 'warehouse-list',
    },
  },
  {
    path: '/warehouse/edit/:id',
    name: 'warehouse-edit',
    component: () => import('@/views/pages/warehouse/WareHouseItem.vue'),
    meta: {
      navActiveLink: 'warehouse-list',
    },
  },
  /** ****************************\
    * Warehouse v2
  \***************************** */
  {
    path: '/warehouse-v2/list',
    name: 'warehousev2-list',
    component: () => import('@/views/pages/warehouse/WareHouseListV2.vue'),
  },
  {
    path: '/warehouse-v2/add',
    name: 'warehousev2-add',
    component: () => import('@/views/pages/warehouse/WareHouseAdd.vue'),
    meta: {
      navActiveLink: 'warehousev2-list',
    },
  },
  {
    path: '/warehouse-v2/edit/:id',
    name: 'warehousev2-edit',
    component: () => import('@/views/pages/warehouse/WareHouseItem.vue'),
    meta: {
      navActiveLink: 'warehousev2-list',
    },
  },
  /** ****************************\
    * inventory receives
  \***************************** */
  {
    path: '/inventory-receives/list',
    name: 'inventory-receives-list',
    component: () => import('@/views/pages/warehouse-v2/inventory-receives/InventoryReceiveList.vue'),
  },
  {
    path: '/inventory-receives/add',
    name: 'inventory-receives-add',
    component: () => import('@/views/pages/warehouse-v2/inventory-receives/InventoryReceiveAdd.vue'),
    meta: {
      navActiveLink: 'inventory-receives-list',
    },
  },
  {
    path: '/inventory-receives/add-v2',
    name: 'inventory-receives-add-v2',
    component: () => import('@/views/pages/warehouse-v2/inventory-receives/InventoryReceiveAddV2.vue'),
    meta: {
      navActiveLink: 'inventory-receives-list',
    },
  },
  {
    path: '/inventory-receives/edit/:id',
    name: 'inventory-receives-edit',
    component: () => import('@/views/pages/warehouse-v2/inventory-receives/InventoryReceiveEdit.vue'),
    meta: {
      navActiveLink: 'inventory-receives-list',
    },
  },
  /** ****************************\
    * purchasing orders
  \***************************** */
  {
    path: '/purchasing-orders/list',
    name: 'purchasing-orders-list',
    component: () => import('@/views/pages/warehouse-v2/purchasing-orders/PurchasingOrderList.vue'),
  },
  {
    path: '/purchasing-orders/add',
    name: 'purchasing-order-add',
    component: () => import('@/views/pages/warehouse-v2/purchasing-orders/PurchasingOrderAdd.vue'),
    meta: {
      navActiveLink: 'purchasing-orders-list',
    },
  },
  {
    path: '/purchasing-orders/edit/:id',
    name: 'purchasing-order-edit',
    component: () => import('@/views/pages/warehouse-v2/purchasing-orders/PurchasingOrderEdit.vue'),
    meta: {
      navActiveLink: 'purchasing-orders-list',
    },
  },
  {
    path: '/purchasing-orders/receives/:id',
    name: 'purchasing-order-receives',
    component: () => import('@/views/pages/warehouse-v2/purchasing-orders/PurchasingOrderReceives.vue'),
    meta: {
      navActiveLink: 'purchasing-orders-list',
    },
  },
  /** ****************************\
    * stock takes
  \***************************** */
  {
    path: '/stock-takes/list',
    name: 'stock-takes-list',
    component: () => import('@/views/pages/warehouse-v2/stock-takes/StockTakesList.vue'),
  },
  {
    path: '/stock-takes/add',
    name: 'stock-takes-add',
    component: () => import('@/views/pages/warehouse-v2/stock-takes/StockTakesAdd.vue'),
    meta: {
      navActiveLink: 'stock-takes-list',
    },
  },
  // {
  //   path: '/stock-takes/edit/:id',
  //   name: 'stock-takes-edit',
  //   component: () => import('@/views/pages/warehouse-v2/stock-takes/InventoryReceives.vue'),
  // },
  /** ****************************\
    * Customer
  \***************************** */
  {
    path: '/customer/list',
    name: 'customer-list',
    component: () => import('@/views/pages/customer/CustomerList.vue'),
  },
  {
    path: '/customer/add',
    name: 'customer-add',
    component: () => import('@/views/pages/customer/CustomerAdd.vue'),
    meta: {
      navActiveLink: 'customer-list',
    },
  },
  {
    path: '/customer/edit/:id',
    name: 'customer-edit',
    component: () => import('@/views/pages/customer/CustomerEdit.vue'),
    meta: {
      navActiveLink: 'customer-list',
    },
  },
  {
    path: '/customer/detail/:id',
    name: 'customer-detail',
    component: () => import('@/views/pages/customer/CustomerDetail.vue'),
    meta: {
      navActiveLink: 'customer-list',
    },
  },
  /** ****************************\
    * Customer Fake
  \***************************** */
  {
    path: '/customer-fake/list',
    name: 'customer-fake-list',
    component: () => import('@/views/pages/customerFake/CustomerFakeList.vue'),
  },
  {
    path: '/customer-fake/add',
    name: 'customer-fake-add',
    component: () => import('@/views/pages/customerFake/CustomerFakeAdd.vue'),
    meta: {
      navActiveLink: 'customer-fake-list',
    },
  },
  {
    path: '/customer-fake/edit/:id',
    name: 'customer-fake-edit',
    component: () => import('@/views/pages/customerFake/CustomerFakeEdit.vue'),
    meta: {
      navActiveLink: 'customer-fake-list',
    },
  },
  {
    path: '/customer-fake/detail/:id',
    name: 'customer-fake-detail',
    component: () => import('@/views/pages/customerFake/CustomerFakeDetail.vue'),
    meta: {
      navActiveLink: 'customer-fake-list',
    },
  },
  /** ****************************\
    * Comment
  \***************************** */
  {
    path: '/comment/list',
    name: 'comment-list',
    component: () => import('@/views/pages/comment/CommentList.vue'),
  },
  {
    path: '/comment/add',
    name: 'comment-add',
    component: () => import('@/views/pages/comment/CommentAdd.vue'),
    meta: {
      navActiveLink: 'comment-list',
    },
  },
  {
    path: '/comment/edit/:id',
    name: 'comment-edit',
    component: () => import('@/views/pages/comment/CommentEdit.vue'),
    meta: {
      navActiveLink: 'comment-list',
    },
  },
  {
    path: '/comment/detail/:id',
    name: 'comment-detail',
    component: () => import('@/views/pages/comment/CommentDetail.vue'),
    meta: {
      navActiveLink: 'comment-list',
    },
  },
  /** ****************************\
    * Attribute
  \***************************** */
  // {
  //   path: '/attribute/add',
  //   name: 'attribute-add',
  //   component: () => import('@/views/pages/attribute/AttributeAdd.vue'),
  // },
  {
    path: '/attribute/edit',
    name: 'attribute-edit',
    component: () => import('@/views/pages/attribute/AttributeEdit.vue'),
  },
  /** ****************************\
    * Trademark
  \***************************** */
  {
    path: '/trademark/list',
    name: 'trademark-list',
    component: () => import('@/views/pages/trademark/TrademarkList.vue'),
  },
  {
    path: '/trademark/add',
    name: 'trademark-add',
    component: () => import('@/views/pages/trademark/TrademarkAdd.vue'),
    meta: {
      navActiveLink: 'trademark-list',
    },
  },
  {
    path: '/trademark/edit/:id',
    name: 'trademark-edit',
    component: () => import('@/views/pages/trademark/TrademarkEdit.vue'),
    meta: {
      navActiveLink: 'trademark-list',
    },
  },
  {
    path: '/trademark/detail/:id',
    name: 'trademark-detail',
    component: () => import('@/views/pages/trademark/TrademarkDetail.vue'),
    meta: {
      navActiveLink: 'trademark-list',
    },
  },
  /** ****************************\
    * Origin
  \***************************** */
  {
    path: '/origin/list',
    name: 'origin-list',
    component: () => import('@/views/pages/origin/OriginList.vue'),
  },
  {
    path: '/origin/add',
    name: 'origin-add',
    component: () => import('@/views/pages/origin/OriginAdd.vue'),
    meta: {
      navActiveLink: 'origin-list',
    },
  },
  {
    path: '/origin/edit/:id',
    name: 'origin-edit',
    component: () => import('@/views/pages/origin/OriginEdit.vue'),
    meta: {
      navActiveLink: 'origin-list',
    },
  },
  {
    path: '/origin/detail/:id',
    name: 'origin-detail',
    component: () => import('@/views/pages/origin/OriginDetail.vue'),
    meta: {
      navActiveLink: 'origin-list',
    },
  },
  /** ****************************\
    * Coupon
  \***************************** */
  {
    path: '/coupon/list',
    name: 'coupon-list',
    component: () => import('@/views/pages/coupon/CouponList.vue'),
  },
  {
    path: '/coupon/add',
    name: 'coupon-add',
    component: () => import('@/views/pages/coupon/CouponAdd.vue'),
    meta: {
      navActiveLink: 'coupon-list',
    },
  },
  {
    path: '/coupon/edit/:id',
    name: 'coupon-edit',
    component: () => import('@/views/pages/coupon/CouponEdit.vue'),
    meta: {
      navActiveLink: 'coupon-list',
    },
  },
  {
    path: '/coupon/detail/:id',
    name: 'coupon-detail',
    component: () => import('@/views/pages/coupon/CouponDetail.vue'),
    meta: {
      navActiveLink: 'coupon-list',
    },
  },
  /** ****************************\
    * Promotion
  \***************************** */
  {
    path: '/promotion/list',
    name: 'promotion-list',
    component: () => import('@/views/pages/promotion/PromotionList.vue'),
  },
  {
    path: '/promotion/add',
    name: 'promotion-add',
    component: () => import('@/views/pages/promotion/PromotionAdd.vue'),
    meta: {
      navActiveLink: 'promotion-list',
    },
  },
  // {
  //   path: '/promotion/add-v2',
  //   name: 'promotion-add-v2',
  //   component: () => import('@/views/pages/promotion/PromotionAddV2.vue'),
  //   meta: {
  //     navActiveLink: 'promotion-list',
  //   },
  // },
  {
    path: '/promotion/edit/:id',
    name: 'promotion-edit',
    component: () => import('@/views/pages/promotion/PromotionEdit.vue'),
    meta: {
      navActiveLink: 'promotion-list',
    },
  },
  {
    path: '/promotion/detail/:id',
    name: 'promotion-detail',
    component: () => import('@/views/pages/promotion/PromotionDetail.vue'),
    meta: {
      navActiveLink: 'promotion-list',
    },
  },
  {
    path: '/flash-sale/list',
    name: 'flash-sale-list',
    component: () => import('@/views/pages/flashsale/FlashSaleList.vue'),
  },
  {
    path: '/flash-sale/add',
    name: 'flashsale',
    component: () => import('@/views/pages/flashsale/FlashSaleAdd.vue'),
    meta: {
      navActiveLink: 'flash-sale-list',
    },
  },
  {
    path: '/flash-sale/edit/:id',
    name: 'flashsale-edit',
    component: () => import('@/views/pages/flashsale/FlashSaleEdit.vue'),
    meta: {
      navActiveLink: 'flash-sale-list',
    },
  },
  /** ****************************\
    * Slider
  \***************************** */
  {
    path: '/slider/list',
    name: 'slider-list',
    component: () => import('@/views/pages/slider/SliderList.vue'),
  },
  {
    path: '/slider/add',
    name: 'slider-add',
    component: () => import('@/views/pages/slider/SliderAdd.vue'),
    meta: {
      navActiveLink: 'slider-list',
    },
  },
  {
    path: '/slider/edit/:id',
    name: 'slider-edit',
    component: () => import('@/views/pages/slider/SliderEdit.vue'),
    meta: {
      navActiveLink: 'slider-list',
    },
  },
  {
    path: '/slider/detail/:id',
    name: 'slider-detail',
    component: () => import('@/views/pages/slider/SliderDetail.vue'),
    meta: {
      navActiveLink: 'slider-list',
    },
  },
  /** ****************************\
    * Supplier
  \***************************** */
  {
    path: '/supplier/list',
    name: 'supplier-list',
    component: () => import('@/views/pages/supplier/SupplierList.vue'),
  },
  {
    path: '/supplier/add',
    name: 'supplier-add',
    component: () => import('@/views/pages/supplier/SupplierAdd.vue'),
    meta: {
      navActiveLink: 'supplier-list',
    },
  },
  {
    path: '/supplier/edit/:id',
    name: 'supplier-edit',
    component: () => import('@/views/pages/supplier/SupplierEdit.vue'),
    meta: {
      navActiveLink: 'supplier-list',
    },
  },
  {
    path: '/supplier/detail/:id',
    name: 'supplier-detail',
    component: () => import('@/views/pages/supplier/SupplierDetail.vue'),
    meta: {
      navActiveLink: 'supplier-list',
    },
  },
  /** ****************************\
    * Service
  \***************************** */
  {
    path: '/service/list',
    name: 'service-list',
    component: () => import('@/views/pages/service/ServiceList.vue'),
  },
  {
    path: '/service/add',
    name: 'service-add',
    component: () => import('@/views/pages/service/ServiceAdd.vue'),
    meta: {
      navActiveLink: 'service-list',
    },
  },
  {
    path: '/service/edit/:id',
    name: 'service-edit',
    component: () => import('@/views/pages/service/ServiceEdit.vue'),
    meta: {
      navActiveLink: 'service-list',
    },
  },
  {
    path: '/service/detail/:id',
    name: 'service-detail',
    component: () => import('@/views/pages/service/ServiceDetail.vue'),
    meta: {
      navActiveLink: 'service-list',
    },
  },
  /** ****************************\
    * FAQ
  \***************************** */
  {
    path: '/faq/list',
    name: 'faq-list',
    component: () => import('@/views/pages/faq/FAQList.vue'),
  },
  {
    path: '/faq/add',
    name: 'faq-add',
    component: () => import('@/views/pages/faq/FAQAdd.vue'),
    meta: {
      navActiveLink: 'faq-list',
    },
  },
  {
    path: '/faq/edit/:id',
    name: 'faq-edit',
    component: () => import('@/views/pages/faq/FAQEdit.vue'),
    meta: {
      navActiveLink: 'faq-list',
    },
  },
  {
    path: '/faq/detail/:id',
    name: 'faq-detail',
    component: () => import('@/views/pages/faq/FAQDetail.vue'),
    meta: {
      navActiveLink: 'faq-list',
    },
  },
  {
    path: '/faq-category/list',
    name: 'faq-category-list',
    component: () => import('@/views/pages/faq/category/List.vue'),
  },
  {
    path: '/faq-category/add',
    name: 'faq-category-add',
    component: () => import('@/views/pages/faq/category/Add.vue'),
    meta: {
      navActiveLink: 'faq-category-list',
    },
  },
  {
    path: '/faq-category/edit/:id',
    name: 'faq-category-edit',
    component: () => import('@/views/pages/faq/category/Edit.vue'),
    meta: {
      navActiveLink: 'faq-category-list',
    },
  },
  /** ****************************\
    * Contact
  \***************************** */
  {
    path: '/contact/list',
    name: 'contact-list',
    component: () => import('@/views/pages/contact/ContactList.vue'),
  },
  {
    path: '/contact/add',
    name: 'contact-add',
    component: () => import('@/views/pages/contact/ContactAdd.vue'),
    meta: {
      navActiveLink: 'contact-list',
    },
  },
  {
    path: '/contact/edit/:id',
    name: 'contact-edit',
    component: () => import('@/views/pages/contact/ContactEdit.vue'),
    meta: {
      navActiveLink: 'contact-list',
    },
  },
  {
    path: '/contact/detail/:id',
    name: 'contact-detail',
    component: () => import('@/views/pages/contact/ContactDetail.vue'),
    meta: {
      navActiveLink: 'contact-list',
    },
  },
  /** ****************************\
    * Language
  \***************************** */
  {
    path: '/language',
    name: 'language',
    component: () => import('@/views/pages/setting/Language.vue'),
  },
  {
    path: '/currency',
    name: 'currency',
    component: () => import('@/views/pages/setting/Currency.vue'),
  },
  /** ****************************\
    * Setting
  \***************************** */
  {
    path: '/info-config',
    name: 'config',
    component: () => import('@/views/pages/setting/Config.vue'),
  },
  /** ****************************\
    * About page
  \***************************** */
  {
    path: '/about-page',
    name: 'about-page',
    component: () => import('@/views/pages/setting/AboutPage.vue'),
  },
  /** ****************************\
    * Sales Channel
  \***************************** */
  {
    path: '/dashboard-channels/',
    name: 'dashboard-channels',
    component: () => import('@/views/pages/salesChannel/dashboardChannels.vue'),
  },
  {
    path: '/channel/',
    name: 'channels',
    component: () => import('@/views/pages/salesChannel/channels.vue'),
  },
  {
    path: '/channel/:id',
    name: 'channel',
    component: () => import('@/views/pages/salesChannel/channel.vue'),
    meta: {
      navActiveLink: 'channels',
    },
  },
  {
    path: '/sales-channel',
    name: 'sales-channel',
    component: () => import('@/views/pages/salesChannel/salesChannel.vue'),
  },
  {
    path: '/sales-channel/shopee-v1',
    name: 'sales-channel-shopee-v1',
    component: () => import('@/views/pages/salesChannel/IndexShopee.vue'),
  },
  {
    path: '/sales-channel/shopee',
    name: 'sales-channel-shopee',
    component: () => import('@/views/pages/salesChannel/IndexShopeeV2.vue'),
  },
  {
    path: '/sales-channel/lazada-v1',
    name: 'sales-channel-lazada-v1',
    component: () => import('@/views/pages/salesChannel/IndexLazada.vue'),
  },
  {
    path: '/sales-channel/lazada',
    name: 'sales-channel-lazada',
    component: () => import('@/views/pages/salesChannel/IndexLazadaV2.vue'),
  },
  {
    path: '/sales-channel/tiktok',
    name: 'sales-channel-tiktok',
    component: () => import('@/views/pages/salesChannel/IndexTiktokV2.vue'),
  },
  {
    path: '/sales-channel/tiktok-v1',
    name: 'sales-channel-tiktok-v1',
    component: () => import('@/views/pages/salesChannel/IndexTiktok.vue'),
  },
  // Shopee
  {
    path: '/sales-channel/sync-shopee/:id',
    name: 'sync-shopee',
    component: () => import('@/views/pages/salesChannel/sync-shopee/SyncProduct.vue'),
    meta: {
      navActiveLink: 'channels',
    },
  },
  {
    path: '/channel/create-shopee/:id',
    name: 'create-shopee',
    component: () => import('@/views/pages/salesChannel/create-shopee/SyncProduct.vue'),
    meta: {
      navActiveLink: 'channels',
    },
  },
  // Lazada
  {
    path: '/channel/sync-lazada/:id',
    name: 'sync-lazada',
    component: () => import('@/views/pages/salesChannel/sync-lazada/SyncProduct.vue'),
    meta: {
      navActiveLink: 'channels',
    },
  },
  {
    path: '/channel/create-lazada/:id',
    name: 'create-lazada',
    component: () => import('@/views/pages/salesChannel/create-lazada/SyncProduct.vue'),
    meta: {
      navActiveLink: 'channels',
    },
  },
  // TIKTOK
  {
    path: '/sales-channel/sync-tiktok/:id',
    name: 'sync-tiktok',
    component: () => import('@/views/pages/salesChannel/sync-tiktok/SyncProduct.vue'),
    meta: {
      navActiveLink: 'channels',
    },
  },
  // {
  //   path: '/channel/create-tiktok/:id',
  //   name: 'sync-tiktok',
  //   component: () => import('@/views/pages/salesChannel/create-tiktok/SyncProduct.vue'),
  //   meta: {
  //     navActiveLink: 'channels',
  //   },
  // },
  /** ****************************\
    * Payment Account
  \***************************** */
  {
    path: '/payment_account',
    name: 'payment-account',
    component: () => import('@/views/pages/setting/PaymentAccount.vue'),
  },
  /** ****************************\
    * Domain
  \***************************** */
  {
    path: '/domain/list',
    name: 'domain-list',
    component: () => import('@/views/pages/domain/DomainList.vue'),
  },
  {
    path: '/domain/add',
    name: 'domain-add',
    component: () => import('@/views/pages/domain/DomainAdd.vue'),
    meta: {
      navActiveLink: 'domain-list',
    },
  },
  {
    path: '/domain/edit/:id',
    name: 'domain-edit',
    component: () => import('@/views/pages/domain/DomainEdit.vue'),
    meta: {
      navActiveLink: 'domain-list',
    },
  },
  {
    path: '/domain/detail/:id',
    name: 'domain-detail',
    component: () => import('@/views/pages/domain/DomainDetail.vue'),
    meta: {
      navActiveLink: 'domain-list',
    },
  },
  /** ****************************\
    * Shipping Setting
  \***************************** */
  {
    path: '/shipping',
    name: 'shipping',
    component: () => import('@/views/pages/shipping/index.vue'),
    // meta: {
    //   navActiveLink: 'domain-list',
    // },
  },
  {
    path: '/shipping/customize',
    name: 'shipping-customize',
    component: () => import('@/views/pages/shipping/CustomizeShipping.vue'),
    meta: {
      navActiveLink: 'shipping',
    },
  },
  {
    path: '/shipping/third-party',
    name: 'shipping-third-party',
    component: () => import('@/views/pages/shipping/ThirdPartyShipping.vue'),
    meta: {
      navActiveLink: 'shipping',
    },
  },
  /** ****************************\
    * Combo product
  \***************************** */
  {
    path: '/combo/list',
    name: 'combo',
    component: () => import('@/views/pages/combo/comboList.vue'),
  },
  {
    path: '/combo/add',
    name: 'combo-add',
    component: () => import('@/views/pages/combo/comboAdd.vue'),
    meta: {
      navActiveLink: 'combo',
    },
  },
  {
    path: '/combo/edit/:id',
    name: 'combo-edit',
    component: () => import('@/views/pages/combo/comboEdit.vue'),
    meta: {
      navActiveLink: 'combo',
    },
  },
  /** ****************************\
    * Bundle deal promotion
  \***************************** */
  {
    path: '/bundle/list',
    name: 'bundle',
    component: () => import('@/views/pages/bundle/bundleList.vue'),
  },
  {
    path: '/bundle/add',
    name: 'bundle-add',
    component: () => import('@/views/pages/bundle/bundleAdd.vue'),
    meta: {
      navActiveLink: 'bundle',
    },
  },
  {
    path: '/bundle/edit/:id',
    name: 'bundle-edit',
    component: () => import('@/views/pages/bundle/bundleEdit.vue'),
    meta: {
      navActiveLink: 'bundle',
    },
  },
  /** ****************************\
    * Account Settings
  \***************************** */
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
  },
  /** ****************************\
    * Chat fanpage Facebook
  \***************************** */
  {
    path: '/fanpage-list',
    name: 'fanpage-list',
    component: () => import('@/views/pages/chat/Index.vue'),
  },
  {
    path: '/chat/:id',
    name: 'chat',
    component: () => import('@/views/pages/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/setting-fb',
    name: 'setting-fb',
    component: () => import('@/views/pages/setting/ConnectFacebook.vue'),
  },
  // user
  {
    path: '/user/add',
    name: 'user-add',
    component: () => import('@/views/pages/user/UserAdd.vue'),
  },
  {
    path: '/user/edit',
    name: 'user-edit',
    component: () => import('@/views/pages/user/UserEdit.vue'),
  },
  {
    path: '/user/list',
    name: 'user-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/user/UserList.vue'),
  },
  {
    path: '/departments/list',
    name: 'departments-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/departments/DepartmentsList.vue'),
  },
  {
    path: '/departments/Add',
    name: 'departments-Add',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/departments/DepartmentsAdd.vue'),
  },
  {
    path: '/purchase-returns/list',
    name: 'purchase-returns-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/warehouse-v2/purchase-returns/PurchaseReturnsList.vue'),
  },
  {
    path: '/purchase-returns/add',
    name: 'purchase-returns-add',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/warehouse-v2/purchase-returns/PurchaseReturnsAdd.vue'),
  },
  {
    path: '/collaborators/list',
    name: 'collaborators-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/Collaborators/CollaboratorsList.vue'),
  },
  {
    path: '/Collaborators/paying',
    name: 'collaborators-paying',
    component: () => import('@/views/pages/Collaborators/CollaboratorsPaying.vue'),
  },
  {
    path: '/Collaborators/view',
    name: 'collaborators-view',
    component: () => import('@/views/pages/Collaborators/users-view/UsersView.vue'),
  },
  {
    path: '/levels/list',
    name: 'levels-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/Collaborators/levels/LevelsList.vue'),
  },
  {
    path: '/levels/add',
    name: 'levels-add',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/Collaborators/levels/LevelsAdd.vue'),
    meta: {
      navActiveLink: 'levels-list',
    },
  },
  {
    path: '/commissionmoney/list',
    name: 'commissionmoney-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/Collaborators/CommissionMoneyList.vue'),
    meta: {
      navActiveLink: 'commissionmoney-list',
    },
  },
  {
    path: '/discount/list',
    name: 'discount-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/discount/DiscountList.vue'),
    meta: {
      navActiveLink: 'discount-list',
    },
  },
  {
    path: '/discount/add',
    name: 'discount-add',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/pages/discount/DiscountAdd.vue'),
    meta: {
      navActiveLink: 'discount-list',
    },
  },
  /** ****************************\
    * Albums product
  \***************************** */
  {
    path: '/album/list',
    name: 'album-list',
    component: () => import('@/views/pages/album/albumList.vue'),
  },
  {
    path: '/album/add',
    name: 'album-add',
    component: () => import('@/views/pages/album/albumAdd.vue'),
    meta: {
      navActiveLink: 'album-list',
    },
  },
  {
    path: '/album/edit/:id',
    name: 'album-edit',
    component: () => import('@/views/pages/album/albumEdit.vue'),
    meta: {
      navActiveLink: 'album-list',
    },
  },
  // Policy
  {
    path: '/policy/list',
    name: 'policy-list',
    component: () => import('@/views/pages/policy/List.vue'),
  },
  {
    path: '/policy/add',
    name: 'policy-add',
    component: () => import('@/views/pages/policy/Add.vue'),
    meta: {
      navActiveLink: 'policy-list',
    },
  },
  {
    path: '/policy/edit/:id',
    name: 'policy-edit',
    component: () => import('@/views/pages/policy/Edit.vue'),
    meta: {
      navActiveLink: 'policy-list',
    },
  },
  // Other
  {
    path: '/about-story/list',
    name: 'about-story-list',
    component: () => import('@/views/pages/other/about-story/List.vue'),
  },
  {
    path: '/about-story/add',
    name: 'about-story-add',
    component: () => import('@/views/pages/other/about-story/Add.vue'),
    meta: {
      navActiveLink: 'about-story-list',
    },
  },
  {
    path: '/about-story/edit/:id',
    name: 'about-story-edit',
    component: () => import('@/views/pages/other/about-story/Edit.vue'),
    meta: {
      navActiveLink: 'about-story-list',
    },
  },
  // Other rank
  {
    path: '/rank/list',
    name: 'rank-list',
    component: () => import('@/views/pages/other/rank/List.vue'),
  },
  {
    path: '/rank/add',
    name: 'rank-add',
    component: () => import('@/views/pages/other/rank/Add.vue'),
    meta: {
      navActiveLink: 'rank-list',
    },
  },
  {
    path: '/rank/edit/:id',
    name: 'rank-edit',
    component: () => import('@/views/pages/other/rank/Edit.vue'),
    meta: {
      navActiveLink: 'rank-list',
    },
  },
  // Other rank info
  {
    path: '/rank-info/list',
    name: 'rank-info-list',
    component: () => import('@/views/pages/other/rank-info/List.vue'),
  },
  {
    path: '/rank-info/add',
    name: 'rank-info-add',
    component: () => import('@/views/pages/other/rank-info/Add.vue'),
    meta: {
      navActiveLink: 'rank-info-list',
    },
  },
  {
    path: '/rank-info/edit/:id',
    name: 'rank-info-edit',
    component: () => import('@/views/pages/other/rank-info/Edit.vue'),
    meta: {
      navActiveLink: 'rank-info-list',
    },
  },
  // Other join-ifashion
  {
    path: '/join-ifashion/list',
    name: 'join-ifashion-list',
    component: () => import('@/views/pages/other/join-ifashion/List.vue'),
  },
  {
    path: '/join-ifashion/add',
    name: 'join-ifashion-add',
    component: () => import('@/views/pages/other/join-ifashion/Add.vue'),
    meta: {
      navActiveLink: 'join-ifashion-list',
    },
  },
  {
    path: '/join-ifashion/edit/:id',
    name: 'join-ifashion-edit',
    component: () => import('@/views/pages/other/join-ifashion/Edit.vue'),
    meta: {
      navActiveLink: 'join-ifashion-list',
    },
  },
  // Other founder
  {
    path: '/founder/list',
    name: 'founder-list',
    component: () => import('@/views/pages/other/founder/List.vue'),
  },
  {
    path: '/founder/add',
    name: 'founder-add',
    component: () => import('@/views/pages/other/founder/Add.vue'),
    meta: {
      navActiveLink: 'founder-list',
    },
  },
  {
    path: '/founder/edit/:id',
    name: 'founder-edit',
    component: () => import('@/views/pages/other/founder/Edit.vue'),
    meta: {
      navActiveLink: 'founder-list',
    },
  },
  // Other reward-quest
  {
    path: '/reward-quest/list',
    name: 'reward-quest-list',
    component: () => import('@/views/pages/other/reward-quest/List.vue'),
  },
  {
    path: '/reward-quest/add',
    name: 'reward-quest-add',
    component: () => import('@/views/pages/other/reward-quest/Add.vue'),
    meta: {
      navActiveLink: 'reward-quest-list',
    },
  },
  {
    path: '/reward-quest/edit/:id',
    name: 'reward-quest-edit',
    component: () => import('@/views/pages/other/reward-quest/Edit.vue'),
    meta: {
      navActiveLink: 'reward-quest-list',
    },
  },
  // Other social media
  {
    path: '/social-media/list',
    name: 'social-media-list',
    component: () => import('@/views/pages/other/social-media/List.vue'),
  },
  {
    path: '/social-media/add',
    name: 'social-media-add',
    component: () => import('@/views/pages/other/social-media/Add.vue'),
    meta: {
      navActiveLink: 'social-media-list',
    },
  },
  {
    path: '/social-media/edit/:id',
    name: 'social-media-edit',
    component: () => import('@/views/pages/other/social-media/Edit.vue'),
    meta: {
      navActiveLink: 'social-media-list',
    },
  },
  {
    path: '/setting/fullpage',
    name: 'setting-fullpage',
    component: () => import('@/views/pages/setting/fullpage-sconcept/FullPage.vue'),
    meta: {
      navActiveLink: 'setting-fullpage',
    },
  },
  {
    path: '/setting/fullpage',
    name: 'setting-fullpage',
    component: () => import('@/views/pages/setting/fullpage-sconcept/FullPage.vue'),
    meta: {
      navActiveLink: 'setting-fullpage',
    },
  },
  {
    path: '/dashboard-v2',
    name: 'dashboard-v2',
    component: () => import('@/views/pages/generalDashboardV2/General.vue'),
    meta: {
      navActiveLink: 'dashboard-v2',
    },
  },
]
