<template>
  <!-- eslint-disable vue/html-self-closing -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div v-if="id && id !== ''">
    <button
      v-b-toggle="id"
      class="px-2 py-1 border-0 br-0 w-100 text-left"
      style="margin-bottom: 2rem;"
    >
      <div class="d-flex align-items-center">
        <div v-if="title && title !== ''" class="flex-grow-1">
          {{ title }}
        </div>
        <feather-icon :icon="icon.name" :size="icon.size" />
      </div>
    </button>
    <b-collapse :id="id" visible style="margin-top: -2rem;" class="bri-0">
      <b-card>
        <slot></slot>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { BCollapse, VBToggle, BCard } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCollapse,
    BCard,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    icon: {
      type: Object,
      default() {
        return { name: 'ChevronDownIcon', size: '18' }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
button {
  background: #e8eaee;
  border-radius: 0.385rem;
}
button:hover,
button:active,
button:focus {
  background: #e8eaee;
  outline: unset;
  border: unset;
}
.br-0 {
  border-radius: 0;
}
.bri-0 {
  .card {
    border-radius: 0;
  }
}
</style>
